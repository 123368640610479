.react-datepicker {
	transition: 0.2s;
}

.react-datepicker__input-container div {
	display: block !important;
	width: 80px;
	text-align: left;
	transition: 0.2s;
}

.react-datepicker__input-container {
	display: block;
	width: 100%;
	height: 45px;
}

.react-datepicker-wrapper input {
	background-color: #fff;
	width: 100% !important;
	height: 45px;
	border: 1px solid rgba(200, 200, 200, 0.5);
	background-color: rgb(255, 255, 255);
	border: solid 1px;
	border-color: rgba(200, 200, 200, 0.5);
	color: #1080d6;
	border-radius: 12px;
	text-align: center;
	transition: 0.2s;
	outline: none;
	transition: 0.2s;
}

.react-datepicker-wrapper input:disabled {
	background-color: #f0f0f0;
	border-color: rgba(200, 200, 200, 0.3);
	color: #a0a0a0;
	cursor: not-allowed;
	transition: 0.2s;
}

.react-datepicker__input-container {
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.2s;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box {
	width: 80px;
	transition: 0.2s;
}
.react-datepicker__month,
.react-datepicker__month-container {
	transition: 0.2s;
}

.react-datepicker__month-container {
	background-color: #ffffff;
	transition: 0.2s;
}

.react-datepicker__header {
	background-color: #ffffff;
	transition: 0.2s;
}

.react-datepicker__current-month {
	font-family: 'Nunito';
	font-style: normal;
	font-weight: 600;
	color: #494949;
	transition: 0.2s;
}

.react-datepicker__day-name {
	font-family: 'Nunito';
	font-weight: 400;
	color: #fff;
	transition: 0.2s;
}

.react-datepicker__month {
	background-color: #ffffff;
	transition: 0.2s;
}

.react-datepicker__day {
	color: rgb(63, 72, 83);
	transition: 0.2s;
}

.react-datepicker__day--selected .react-datepicker__day--today {
	color: #fff;
	transition: 0.2s;
}

.react-datepicker__day--selected {
	background-color: #1080d6;
	color: #fff;
	transition: 0.2s;
}

.react-datepicker__header {
	margin-bottom: 10px;
	padding: 10px;
}

.react-datepicker__day-names {
	display: none;
}

.react-datepicker__navigation {
	color: #1080d6;
	transition: 0.2s;
}

.react-datepicker__navigation-icon {
	transition: 0.2s;
	min-width: 25px;
	max-width: 25px;
	min-height: 25px;
	max-height: 25px;
}

.react-datepicker {
	transition: 0.2s;
	border: 1px solid rgb(63, 72, 83, 0.3); /* Adiciona o contorno azul */
}

.react-datepicker__header {
	border: none;
}
