* {
	margin: 0;
	box-sizing: border-box;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
	outline: none;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

code {
	font-family:
		'Nunito',
		source-code-pro,
		Menlo,
		Monaco,
		Consolas monospace;
}
